import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Ant from "antd";
import { PlusOutlined, QuestionCircleTwoTone } from "@ant-design/icons";
import { navigate } from "gatsby";
import { fuzzy } from "fast-fuzzy";
import moment from "moment";

import { Font } from "../Components/Widget";
import UserList from "../Components/UserList";
import SearchInput from "../Components/SearchInput";

import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { withPage } from "../PageContainer";
import * as Survey from "../Contexts/SurveyContext";

/**
 * 新增帳號 Modal
 * @param {visible} bool 是否呈現
 * @param {onCancel} func 取消 callback
 * @param {selectUser} obj 預設用戶物件
 * @param {userList} array 用戶列表，目前沒用
 * @param {filters} array 過濾條件，用來比對 userCode & phone 是否重複
 * @return {CreateUserModal}
 */
function CreateUserModal({ visible, onCancel, selectUser, userList, filters }) {
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [roleId, setRoleId] = useState(2);
  const [status, setStatus] = useState(1);

  const [from, setFrom] = useState(0);
  // 0 問卷平台
  // 1 CRM行銷平台

  const [isNameError, setNameIsError] = useState(false);
  const [nameHelpText, setNameHelpText] = useState("");
  const [isUserNameError, setUserNameIsError] = useState(false);
  const [usernameHelpText, setUserNameHelpText] = useState("");
  const [isPhoneError, setPhoneIsError] = useState(false);
  const [phoneHelpText, setPhoneHelpText] = useState("");

  // 系統管理員 tooltip
  const systemTipContent = (
    <SystemTip>
      <p>• 可以瀏覽【帳號列表】，提供新增/修改/停用帳號功能</p>
      <p>• 可以【建立團隊】並自行設定團隊成員權限 (擁有/編輯/瀏覽)</p>
      <p>• 可以看到並編輯所有人的問卷</p>
    </SystemTip>
  );
  // 行銷人員 tooltip
  const salesTipContent = (
    <SystemTip>
      <p>• 由 CRM 建立帳號並與問卷平台串接</p>
      <p>• 只能編輯自己的問卷</p>
      <p>• 可以【建立團隊】並自行設定團隊成員權限 (擁有/編輯/瀏覽)</p>
    </SystemTip>
  );
  // 第三方行銷人員 tooltip
  const thirdTipContent = (
    <SystemTip>
      <p>• 由【系統管理員】手動新增【第三方行銷人員】這個角色</p>
      <p>• 可以【建立團隊】並自行設定團隊成員權限 (擁有/編輯/瀏覽)</p>
      <p>• 僅能編輯問卷內容，不能建立、陳核、下載資料</p>
    </SystemTip>
  );

  /**
   * 使用者Modal按下送出後觸發
   * @returns
   */
  async function submitUser() {
    try {
      let data = {
        userId: userId,
        name: name,
        username: username,
        phone: phone,
        roleId: roleId,
        status: status,
      };
      console.log(data);
      resetErrorInfo();
      console.log(validateUserInfo(data));
      if (validateUserInfo(data) === false) return;

      let resJSON = await Survey.Actions.submitAccount(data);

      if (resJSON.code && resJSON.code === 200) {
        resetErrorInfo();
        onCancel();
        // TODO 把  window.location.reload 拿掉
        window.location.reload();
      } else {
        if (resJSON.code && resJSON.code === 401) {
          setNameIsError(true);
          setNameHelpText("已有相同姓名存在");
        } else if (resJSON.code && resJSON.code === 402) {
          setUserNameIsError(true);
          setUserNameHelpText("此組帳號已被使用，請重新輸入帳號");
        } else if (resJSON.code && resJSON.code === 403) {
          setPhoneIsError(true);
          setPhoneHelpText("此組手機號碼已被使用，請重新輸入手機號碼");
        } else {
          alert(resJSON.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const resetErrorInfo = () => {
    setNameIsError(false);
    setNameHelpText("");
    setUserNameIsError(false);
    setUserNameHelpText("");
    setPhoneIsError(false);
    setPhoneHelpText("");
  };

  /**
   * 表單驗證主邏輯
   * @param {} data form data
   * @returns
   */
  const validateUserInfo = data => {
    let isPasses = true;

    if (data.userId === "") {
      if (data.name === "") {
        setNameIsError(true);
        setNameHelpText("請輸入姓名");
        isPasses = false;
      } else {
        if (data.name.length < 2 || data.name.length > 10) {
          setNameIsError(true);
          setNameHelpText("姓名字數為2~10，請重新設定");
          isPasses = false;
        }
      }
      if (data.username === "") {
        setUserNameIsError(true);
        setUserNameHelpText("請輸入帳號");
        isPasses = false;
      } else {
        var pattern = /[~!@#$^&*()\-+=?><。`]/;
        if (pattern.test(data.username)) {
          setUserNameIsError(true);
          setUserNameHelpText("使用者帳號不能包含特殊字元");
        }
        var pp = /^[A-Za-z0-9]+$/;
        if (!pp.test(data.username)) {
          setUserNameIsError(true);
          setUserNameHelpText("使用者帳號只能輸入英文與數字");
          isPasses = false;
        }
        if (data.username.length < 8 || data.username.length > 20) {
          setUserNameIsError(true);
          setUserNameHelpText("使用者帳號字數為8~20，請重新設定");
          isPasses = false;
        }
      }
      if (data.phone === "") {
        setPhoneIsError(true);
        setPhoneHelpText("請輸入手機號碼");
        isPasses = false;
      } else {
        var phoneReg = /^09[0-9]{8}$/g;
        if (!phoneReg.test(data.phone) || data.phone.length !== 10) {
          setPhoneIsError(true);
          setPhoneHelpText("請輸入09開頭共10碼數字");
          isPasses = false;
        }
      }
    } else {
      if (data.name === "") {
        setNameIsError(true);
        setNameHelpText("請輸入姓名");
        isPasses = false;
      } else {
        if (data.name.length < 2 || data.name.length > 10) {
          setNameIsError(true);
          setNameHelpText("姓名字數為2~10，請重新設定");
          isPasses = false;
        }
      }
      if (data.phone === "") {
        setPhoneIsError(true);
        setPhoneHelpText("請輸入手機號碼");
        isPasses = false;
      } else {
        var phoneReg = /^09[0-9]{8}$/g;
        if (!phoneReg.test(data.phone) || data.phone.length !== 10) {
          setPhoneIsError(true);
          setPhoneHelpText("請輸入09開頭共10碼數字");
          isPasses = false;
        }
      }
    }

    return isPasses;
  };

  /**
   * 改變姓名時觸發
   * @param {*} e
   */
  const handleChangeName = e => {
    let isPasses = true;
    const val = e.target.value;
    const pattern = /^[\u4e00-\u9fa5]+$/; // regex 只能輸入中文

    if (val !== "") {
      if (
        !pattern.test(val) ||
        (val.length > 0 && (val.length < 2 || val.length > 10))
      ) {
        setNameIsError(true);
        setNameHelpText(
          "不能包含字母、數字與符號，姓名字數為2~10個字，請重新設定"
        );
        isPasses = false;
      }
    }
    if (isPasses) {
      setNameIsError(false);
      setNameHelpText("");
    }
    setName(val);
  };

  /**
   * 改變使用者帳號時觸發
   * @param {*} e
   */
  const handleChangeUserName = e => {
    let isPasses = true;
    const val = e.target.value;
    const pattern = /[~!@#$%^&*()-+=?><。]/;

    const pp = /^[A-Za-z0-9_.]+$/;
    // 前端判斷帳號是否重複
    const isUserCodeExist = filters.userCode.find(
      code => code.toUpperCase() === val.toUpperCase()
    );

    if (val !== "") {
      if (isUserCodeExist) {
        setUserNameIsError(true);
        setUserNameHelpText("此組帳號已被使用，請重新設定帳號");
        isPasses = false;
      } else if (pattern.test(val)) {
        setUserNameIsError(true);
        setUserNameHelpText(
          "使用者名稱不能包含：~!@#$%^&*()-+=?><。等特殊符號，請修改。"
        );
        isPasses = false;
      } else if (
        !pp.test(val) ||
        (val.length > 0 && (val.length < 8 || val.length > 20))
      ) {
        setUserNameIsError(true);
        setUserNameHelpText(
          "請輸入8-20字元，可包含英文字母 (A-Z且大小寫視為相同) 、數字 (0-9) 或符號(_.)的組合。"
        );
        isPasses = false;
      }
    }
    if (isPasses) {
      setUserNameIsError(false);
      setUserNameHelpText("");
    }
    setUserName(val);
  };

  /**
   * 改變電話時觸發
   * @param {*} e
   */
  const handleChangePhone = e => {
    let isPasses = true;
    const val = e.target.value;
    const regexPhone = /^09[0-9]{8}$/g;
    // 前端判斷電話是否重複
    const isUserPhoneExist = filters.userPhone.find(phone => phone === val);

    if (val.length !== 10 || !regexPhone.test(val)) {
      setPhoneIsError(true);
      setPhoneHelpText("請輸入09開頭共10碼數字");
      isPasses = false;
    } else if (isUserPhoneExist) {
      setPhoneIsError(true);
      setPhoneHelpText("此組手機號碼已被使用，請重新輸入手機號碼");
      isPasses = false;
    }
    if (isPasses) {
      setPhoneIsError(false);
      setPhoneHelpText("");
    }
    setPhone(val);
  };

  // 初始化使用者資訊，如果點選編輯則把使用者資訊填入
  useEffect(() => {
    setUser(selectUser ?? {});
    setUserId(selectUser ? selectUser.userid : "");
    setName(selectUser ? selectUser.name : "");
    setUserName(selectUser ? selectUser.username : "");
    setPhone(selectUser ? selectUser.phone : "");
    setRoleId(selectUser ? selectUser.roleId : 2);
    setStatus(selectUser ? selectUser.status : 1);
    setFrom(selectUser ? selectUser.from : 0);
  }, [selectUser]);

  // 關閉modal時清空所有欄位
  useEffect(() => {
    resetErrorInfo();
  }, [visible]);

  return (
    <>
      <Ant.Modal
        className="custom-modal"
        title={selectUser ? "帳號內容 - " + selectUser.name : "新增帳號"}
        visible={visible}
        width={600}
        onCancel={() => {
          resetErrorInfo();
          onCancel();
        }}
        footer={[
          <Ant.Button key="back" onClick={onCancel} style={{ width: 180 }}>
            取消
          </Ant.Button>,
          <Ant.Button
            key="submit"
            type="primary"
            onClick={async () => {
              await submitUser();
            }}
            style={{ width: 180 }}
          >
            確認
          </Ant.Button>,
        ]}
      >
        <div>
          <Ant.Form layout="vertical">
            {selectUser ? (
              <div>
                基本資料
                <Ant.Divider />
                <Ant.Row gutter={[16, 0]}>
                  <Ant.Col span={12}>
                    <Ant.Form.Item
                      label="姓名"
                      validateStatus={isNameError ? "error" : ""}
                      help={nameHelpText ? nameHelpText : ""}
                    >
                      <Ant.Input
                        value={name}
                        disabled={user.from === 1}
                        name="name"
                        onChange={handleChangeName}
                      />
                    </Ant.Form.Item>
                  </Ant.Col>
                  <Ant.Col span={12}>
                    <Ant.Form.Item
                      label="使用者帳號"
                      validateStatus={isUserNameError ? "error" : ""}
                      help={usernameHelpText ? usernameHelpText : ""}
                    >
                      <Ant.Input
                        value={username}
                        disabled
                        name="username"
                        onChange={handleChangeUserName}
                      />
                    </Ant.Form.Item>
                  </Ant.Col>
                </Ant.Row>
                <Ant.Row gutter={[16, 24]}>
                  <Ant.Col span={12}>
                    <Ant.Form.Item
                      label="手機號碼"
                      validateStatus={isPhoneError ? "error" : ""}
                      help={phoneHelpText ? phoneHelpText : ""}
                    >
                      <Ant.Input
                        value={phone}
                        disabled={user.from === 1}
                        name="phone"
                        maxLength="10"
                        onChange={handleChangePhone}
                      />
                    </Ant.Form.Item>
                  </Ant.Col>
                </Ant.Row>
                角色權限
                <Ant.Divider />
                <Ant.Form.Item label="角色">
                  <Ant.Radio.Group
                    value={roleId}
                    onChange={e => {
                      setRoleId(e.target.value);
                    }}
                    style={{ display: "block" }}
                  >
                    <Ant.Row gutter={[16, 8]}>
                      <Ant.Col span={8}>
                        <Ant.Radio value={1}>
                          系統管理員
                          <span style={{ marginLeft: 5 }}>
                            <Ant.Tooltip
                              placement="bottom"
                              title={systemTipContent}
                            >
                              <QuestionCircleTwoTone twoToneColor="#e09d3f" />
                            </Ant.Tooltip>
                          </span>
                        </Ant.Radio>
                      </Ant.Col>
                      <Ant.Col span={8}>
                        <Ant.Radio value={2}>
                          行銷人員
                          <span style={{ marginLeft: 5 }}>
                            <Ant.Tooltip
                              placement="bottom"
                              title={salesTipContent}
                            >
                              <QuestionCircleTwoTone twoToneColor="#e09d3f" />
                            </Ant.Tooltip>
                          </span>
                        </Ant.Radio>
                      </Ant.Col>
                      <Ant.Col span={8}>
                        <Ant.Radio value={3}>
                          第三方行銷人員
                          <span style={{ marginLeft: 5 }}>
                            <Ant.Tooltip
                              placement="bottom"
                              title={thirdTipContent}
                            >
                              <QuestionCircleTwoTone twoToneColor="#e09d3f" />
                            </Ant.Tooltip>
                          </span>
                        </Ant.Radio>
                      </Ant.Col>
                    </Ant.Row>
                  </Ant.Radio.Group>
                </Ant.Form.Item>
                帳號狀態
                <Ant.Divider />
                <Ant.Row gutter={[16, 0]}>
                  <Ant.Col span={24}>
                    <Ant.Form.Item label="帳號來源">
                      <Ant.Input
                        value={user.from === 0 ? "問卷平台" : "CRM行銷平台"}
                        disabled
                        name="from"
                        onChange={e => {}}
                      />
                    </Ant.Form.Item>
                  </Ant.Col>
                </Ant.Row>
                <Ant.Row gutter={[16, 0]}>
                  <Ant.Col span={12}>
                    <Ant.Form.Item label="帳號建立時間">
                      <Ant.Input
                        value={
                          user.created
                            ? moment(user.created).format("YYYY/MM/DD HH:mm:ss")
                            : ""
                        }
                        disabled
                        name="created"
                        onChange={e => {}}
                      />
                    </Ant.Form.Item>
                  </Ant.Col>
                  <Ant.Col span={12}>
                    <Ant.Form.Item label="最後登入時間">
                      <Ant.Input
                        value={
                          user.lastLoginAt
                            ? moment(user.lastLoginAt).format(
                                "YYYY/MM/DD HH:mm:ss"
                              )
                            : ""
                        }
                        disabled
                        name="lastLoginAt"
                        onChange={e => {}}
                      />
                    </Ant.Form.Item>
                  </Ant.Col>
                </Ant.Row>
                <Ant.Row gutter={[16, 0]}>
                  <Ant.Col span={8}>
                    <Ant.Form.Item label="帳號狀態">
                      <Ant.Select
                        value={status}
                        disabled={user.from === 1}
                        style={{
                          backgroundColor:
                            user.from === 1 ? "#f3f3f3" : "#ffffff",
                        }}
                        onChange={v => {
                          setStatus(v);
                        }}
                      >
                        <Ant.Select.Option value={0}>停用</Ant.Select.Option>
                        <Ant.Select.Option value={1}>啟用</Ant.Select.Option>
                      </Ant.Select>
                    </Ant.Form.Item>
                  </Ant.Col>
                  <Ant.Col span={8}>
                    <Ant.Form.Item label="時間">
                      <Ant.Input
                        value={
                          user.updated
                            ? moment(user.updated).format("YYYY/MM/DD HH:mm:ss")
                            : ""
                        }
                        disabled
                        name="updated"
                        onChange={e => {}}
                      />
                    </Ant.Form.Item>
                  </Ant.Col>
                </Ant.Row>
              </div>
            ) : (
              <div>
                <Ant.Form.Item
                  label="姓名"
                  validateStatus={isNameError ? "error" : ""}
                  help={nameHelpText ? nameHelpText : ""}
                >
                  <Ant.Input
                    placeholder="請輸入中文，字數限於 2~10 個字之間"
                    name="name"
                    value={name}
                    onChange={handleChangeName}
                  />
                </Ant.Form.Item>
                <Ant.Form.Item
                  label="使用者帳號"
                  validateStatus={isUserNameError ? "error" : ""}
                  help={usernameHelpText ? usernameHelpText : ""}
                >
                  <Ant.Input
                    placeholder="請輸入8-20個字元，可包含英文字母 (A-Z且大小寫視為相同) 、數字 (0-9) 或符號(_.)的組合"
                    name="username"
                    value={username}
                    onChange={handleChangeUserName}
                  />
                </Ant.Form.Item>
                <Ant.Form.Item
                  label="手機號碼"
                  validateStatus={isPhoneError ? "error" : ""}
                  help={phoneHelpText ? phoneHelpText : ""}
                >
                  <Ant.Input
                    placeholder="請輸入09開頭共10碼數字"
                    name="phone"
                    maxLength="10"
                    value={phone}
                    onChange={handleChangePhone}
                  />
                </Ant.Form.Item>
                <Ant.Form.Item label="角色">
                  <Ant.Radio.Group
                    value={roleId}
                    onChange={e => {
                      setRoleId(e.target.value);
                    }}
                    style={{ display: "block" }}
                  >
                    <Ant.Row gutter={[16, 8]}>
                      <Ant.Col span={8}>
                        <Ant.Radio value={1}>
                          系統管理員
                          <span style={{ marginLeft: 5 }}>
                            <Ant.Tooltip
                              placement="bottom"
                              title={systemTipContent}
                            >
                              <QuestionCircleTwoTone twoToneColor="#e09d3f" />
                            </Ant.Tooltip>
                          </span>
                        </Ant.Radio>
                      </Ant.Col>
                      <Ant.Col span={8}>
                        <Ant.Radio value={2}>
                          行銷人員
                          <span style={{ marginLeft: 5 }}>
                            <Ant.Tooltip
                              placement="bottom"
                              title={salesTipContent}
                            >
                              <QuestionCircleTwoTone twoToneColor="#e09d3f" />
                            </Ant.Tooltip>
                          </span>
                        </Ant.Radio>
                      </Ant.Col>
                      <Ant.Col span={8}>
                        <Ant.Radio value={3}>
                          第三方行銷人員
                          <span style={{ marginLeft: 5 }}>
                            <Ant.Tooltip
                              placement="bottom"
                              title={thirdTipContent}
                            >
                              <QuestionCircleTwoTone twoToneColor="#e09d3f" />
                            </Ant.Tooltip>
                          </span>
                        </Ant.Radio>
                      </Ant.Col>
                    </Ant.Row>
                  </Ant.Radio.Group>
                </Ant.Form.Item>
              </div>
            )}
          </Ant.Form>
        </div>
      </Ant.Modal>
    </>
  );
}

/**
 * 帳號列表頁面
 * @param {profile} object 用戶資訊
 * @return {UserListPage}
 */
function UserListPage({ profile, ...props }) {
  const { appActions } = props;
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectUser, setSelectUser] = useState(null);
  const [filter, setFilter] = useState("");

  const [filters, setFilters] = useState({
    userName: [],
    userCode: [],
    userPhone: [],
    role: [],
    status: [],
    from: [],
  });
  const [pageInfo, setPageInfo] = useState({ current: 1, total: 0 });
  const [queryInfo, setQueryInfo] = useState({});
  const [page, setPage] = useState(1);
  const perPage = 10;

  let windowGlobal = typeof window !== "undefined" && window;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    windowGlobal = typeof window !== "undefined" && window;
    let token = windowGlobal.localStorage.getItem("token");

    /**
     * 取得帳號列表，並且分頁
     */
    async function fetchData() {
      setLoading(true);
      try {
        const users = await Survey.Actions.fetchAccountList(
          profile,
          page,
          perPage,
          filter,
          queryInfo
        );
        setUsers(users.data);
        setFilters(users.filters);
        setPageInfo(users.pagination);
      } catch (ex) {
        // alert("系統異常，請洽系統管理員");
      }
      setLoading(false);
    }

    if (profile && token && profile && profile.RoleId < 2) {
      fetchData();
    }

    // 資格不符，回首頁
    if (!profile || !token) {
      appActions.logout();
      console.debug("各路工程師想要使用者回老家");
      navigate("/");
    } else if (profile && profile.RoleId > 1) {
      console.debug("各路工程師想要使用者回老家");
      navigate("/");
    }
  }, [profile, page, queryInfo, filter]);

  return (
    <Wrapper>
      <div className="content">
        <Font.LargeTitle style={{ marginBottom: 34 }}>帳號列表</Font.LargeTitle>
        <Ant.Row justify="space-between">
          <Ant.Button
            type="primary"
            icon={<PlusOutlined size={14} />}
            style={{ height: 40, minWidth: 120 }}
            onClick={() => {
              setShowCreateModal(true);
              setSelectUser(null);
            }}
          >
            新增帳號
          </Ant.Button>
          {/* <Ant.Input
            className="search-input"
            placeholder="姓名、帳號..."
            suffix={
              <Icon.Search
                size={24}
                color={Color.Purple}
                width="auto"
                height="auto"
              />
            }
            size="large"
            style={{ width: 264 }}
            allowClear
            onChange={e => setFilter(e.target.value)}
          /> */}
          <SearchInput
            placeholder="姓名、帳號..."
            onChange={e => {
              setPage(1);
              setFilter(e.target.value);
            }}
          />
        </Ant.Row>
        {profile && profile.RoleId < 2 && (
          <UserList
            users={users}
            filters={filters}
            loading={loading}
            openEditModal={user => {
              setShowCreateModal(true);
              setSelectUser(user);
            }}
            pageInfo={pageInfo}
            perPage={perPage}
            onPageChange={page => {
              setPage(page);
            }}
            onTableChange={(selectedRowKeys, selectedRows, info) => {
              setPage(1);
              setQueryInfo(selectedRows);
            }}
          />
        )}
        <CreateUserModal
          userList={users}
          filters={filters}
          visible={showCreateModal}
          selectUser={selectUser}
          onCancel={() => {
            setShowCreateModal(false);
            setSelectUser(null);
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fafafa;
  min-height: calc(100vh - 64px);
  overflow: hidden;

  & > .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 32px 36px 0;
  }
`;

const SystemTip = styled.div``;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(UserListPage)
);
